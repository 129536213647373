<template>
    <div class="final__card">
        <h2
            class="final__title"
            :class="[
                finalTypes[data.type].class
            ]"
        >
            {{data.title}}
        </h2>
        <!-- <img
            :src="require(`../assets/images/${selectedPerson.id}_fail.png`)"
            class="final__person"
            alt=""
        /> -->
        <p class="final__message">
            {{data.message}}
        </p>
        <div class="final__card__actions try-again">
            <router-link
                :to="{name: data.type !== finalTypes.ONE_MORE_TYPE ? 'Home' : 'Quiz'}"
                class="button button--invert"
            >
                {{data.type !== finalTypes.ONE_MORE_TYPE ? 'Попробовать еще раз' : 'Продолжить'}}
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {FINAL_TYPES} from '@/core/constants';

export default {
    name: 'Fail',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('persons', [
            'selectedPerson',
        ]),
        finalTypes() {
            return FINAL_TYPES;
        },
    },
};
</script>
