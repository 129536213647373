<template>
    <div class="final" :style="{ justifyContent: !finalData.serviceData && 'center' }">
        <div class="final__col">
            <transition name="scale-in">
                <FinalCard
                    v-show="isLoaded"
                    :data="finalData"
                />
            </transition>
        </div>
        <div v-if="finalData.serviceData" class="final__col">
            <transition name="scale-in">
                <div v-show="isLoaded" class="final__card service-card">
                    <div class="final__card__header">
                        <img src="@/assets/images/service-header.png" alt="">
                    </div>
                    <h1 class="service-card__title">
                        {{finalData.serviceData.title}}
                    </h1>
                    <p class="final__message">
                        {{finalData.serviceData.description}}
                    </p>
                    <div class="final__card__actions">
                        <a
                            :href="finalData.serviceData.link"
                            class="button"
                        >
                            {{finalData.serviceData.linkTitle}}
                        </a>
                        <a class="link" href="https://www.centrinvest.ru/">
                            Перейти на сайт банка
                        </a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import FinalCard from '@/components/final/FinalCard';

export default {
    name: 'Final',
    components: {
        FinalCard,
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        ...mapGetters('quiz', [
            'finalData',
        ]),
        ...mapGetters('persons', [
            'selectedPerson',
        ]),
    },
    mounted() {
        try {
            window && window.ym && window.ym(88443543, 'reachGoal', 'end');
        } catch (error) {
            console.error(error);
        }

        this.isLoaded = true;
    },
};
</script>
